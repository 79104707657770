// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");

import { Modal } from "bootstrap";
window.Modal = Modal;
import "../stylesheets/application";
import "@fortawesome/fontawesome-free/js/solid.js";
import "@fortawesome/fontawesome-free/js/fontawesome.js";
import AppEventTracker from "../src/app_event_tracker";
window.AppEventTracker = AppEventTracker;
import ahoy from "ahoy.js";
ahoy.configure({ cookies: false });
window.ahoy = ahoy;

require("../src/live_session_counter");
import "rollbar";
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context("../images", true);
// const imagePath = (name) => images(name, true);

window.copy_to_clipboard = (el, text) => {
  navigator.clipboard.writeText(text).then(
    function () {
      // alert('Copied "' + text + '" to clipboard.');
      const checkmark = document.createElement("i");
      checkmark.classList.add("fa", "fa-check", "text-success");
      el.parentNode.appendChild(checkmark);
      setTimeout(() => {
        // el.parentNode remove last child
        checkmark.remove();
        el.parentNode
          .querySelectorAll("svg.fa-check")
          .forEach((c) => c.remove());
      }, 1000);
    },
    function () {
      alert(
        "There was an error copying text. Please ensure javascript is turned on."
      );
    }
  );

  return false;
};

window.add_array_input = (e) => {
  let element = e.target;
  let clone = document.querySelector(".array-field").cloneNode(true);
  clone.value = "";
  element.parentNode.insertBefore(clone, element);
};

document.addEventListener("DOMContentLoaded", () => {
  new AppEventTracker();

  var array_button = document.querySelector(".array-add-btn");
  if (array_button) array_button.addEventListener("click", add_array_input);

  if (window.pushData) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(pushData);
    window.pushData = null;
  }
});

window.debounce = (func, wait, immediate) => {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};
