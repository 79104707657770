import consumer from "./consumer";

let ice;
let pc;

const startPeerConnect = () => {
  let video = document.querySelector('video');

  ice = { iceServers: ICE_SERVERS };
  pc = new RTCPeerConnection(ice);
  pc.ontrack = (e) => {
    video.srcObject = e.streams[0];
  };
  pc.onicecandidate = (event) => {
    sendData({ candidate: JSON.stringify(event.candidate), message_type: 8 })
  };
};

const addIceCandidate = (data) => {
  try {
    pc.addIceCandidate(new RTCIceCandidate(JSON.parse(data.candidate)));
  } catch (error) {
    logError(error);
  }
};

const setRemoteDescription = (data) => {
  const sdp = JSON.parse(data.sdp);
  pc.setRemoteDescription(new RTCSessionDescription(sdp))
    .then(() => {
      if (sdp.type === "offer") {
        pc.createAnswer()
          .then((answer) => {
            return pc.setLocalDescription(answer);
          })
          .then(() => {
            sendData({ sdp: JSON.stringify(pc.localDescription), message_type: 7 })
          });
      }
    }).catch(logError);
};

window.readStream = (session_token) => {
  window.socket_channel = consumer.subscriptions.create({ channel: "RepeaterChannel", session_token: session_token }, {
    connected: () => {
      console.log('ready to accept stream: ' + session_token)
      sendData({ init: true, message_type: 6 });
      startPeerConnect();
    },
    received: (data) => {
      console.log(data)
      if(data.sdp)
        setRemoteDescription(data);
      if(data.candidate)
        addIceCandidate(data);
      if(data.session_ended){
        clearInterval(window.intervalId);
        if(live) alert('Player has left the game session.');
      }
    }
  });
};

const sendData = (data) => {
  data.action = 'rtc_subscribe';
  console.log(data)
  window.socket_channel.send(data)
};

const logError = (error) => {
  console.warn("Whoops! Error:", error);
  sendData({ error: error })
};