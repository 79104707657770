var totalSeconds = 0;
document.addEventListener('DOMContentLoaded', () => {
  if(window.intervalId)
    clearInterval(window.intervalId);
  window.intervalId = setInterval(countTimer, 1000);
});

function countTimer() {
  document.querySelectorAll('.is-timed').forEach(timer => {
    var timerSeconds = parseInt(timer.dataset['seconds']) + 1;
    timer.dataset['seconds'] = timerSeconds;
    var hour = Math.floor(timerSeconds / 3600);
    var minute = Math.floor((timerSeconds - hour * 3600) / 60);
    var seconds = timerSeconds - (hour * 3600 + minute * 60);
    if(hour < 10)
      hour = "0"+hour;
    if(minute < 10)
      minute = "0"+minute;
    if(seconds < 10)
      seconds = "0"+seconds;
    timer.innerHTML = hour + ":" + minute + ":" + seconds;
  });
};
