import consumer from "./consumer";

let ice;
let pc;

const startPeerConnect = () => {
  let canvas = document.querySelector("#unityContainer canvas");

  ice = { iceServers: ICE_SERVERS };
  pc = new RTCPeerConnection(ice);

  let stream = canvas.captureStream();
  stream.getTracks().forEach((track) => {
    pc.addTrack(track, stream);
  });

  pc.onicecandidate = (event) => {
    sendData({ candidate: JSON.stringify(event.candidate) });
  };

  pc.createOffer()
    .then((offer) => {
      return pc.setLocalDescription(offer);
    })
    .then(() => {
      sendData({ sdp: JSON.stringify(pc.localDescription) });
    })
    .catch(logError);
};

const addIceCandidate = (data) => {
  try {
    pc.addIceCandidate(new RTCIceCandidate(JSON.parse(data.candidate)));
  } catch (error) {
    logError(error);
  }
};

const setRemoteDescription = (data) => {
  try {
    pc.setRemoteDescription(new RTCSessionDescription(JSON.parse(data.sdp)));
  } catch (error) {
    logError(error);
  }
};

window.createSubscription = (session_token, student_code) => {
  ahoy.track("Session Started", {
    session_token: session_token,
    student_code: student_code,
  });
  window.socket_channel = consumer.subscriptions.create(
    { channel: "StreamChannel", session_token: session_token },
    {
      connected: () => {
        console.log("starting stream: " + session_token);
      },
      received: (data) => {
        console.log(data);
        if (data.init) startPeerConnect();
        if (data.sdp) setRemoteDescription(data);
        if (data.candidate) addIceCandidate(data);
        if (data.session_end) {
          window.onbeforeunload = null;
          window.location.replace(
            "/session_complete?session_id=" + session_token
          );
        }
      },
    }
  );

  var url = document.querySelector("#root-url").dataset["url"];
  return url;
};

const sendData = (data) => {
  data.action = "rtc_subscribe";
  console.log(data);
  window.socket_channel.send(data);
};
const logError = (error) => {
  console.warn("Whoops! Error:", error);
  sendData({ error: error });
};
