import consumer from "./consumer"

document.addEventListener('DOMContentLoaded', () => {
  const advisor = document.querySelector('#advisor-id')
  if(!advisor) return;

  let advisor_id = advisor.getAttribute('data-advisor-id')

  if(advisor_id){
    consumer.subscriptions.create({ channel: "AdvisorChannel", advisor_id: advisor_id }, {
      connected() {
        console.log('connected to advisor channel')
      },

      disconnected() {
        console.log('disconnected from advisor channel')
      },

      received(data) {
        var studentDiv = document.querySelector("[data-student-id='"+ data.student_id +"'].student");
        if(data.student_id && studentDiv)
          studentDiv.innerHTML = data.html_data;
      }
    });
  }
})