class AppEventTracker{
  constructor(){
    console.log('...booting event tracker.')
    this.queryDom()
    this.attachListeners()
  }

  queryDom() {
    this.dataContainer = document.querySelectorAll('.event-data');
  }

  attachListeners() {
    let csrf_token = document.querySelector('meta[name="csrf-token"]').content;
    this.dataContainer.forEach(el => {
      let listenerConfig = el.querySelector('.listener-config');
      if(listenerConfig)
        listenerConfig = listenerConfig.dataset;
      else
        return;

      if(listenerConfig.eventTrigger == 'timer'){
        setTimeout(
          this.sendData, parseInt(listenerConfig.eventDelay), listenerConfig.data, csrf_token
        )
      } else if(listenerConfig.eventTrigger == 'click') {
        let clickElem = document.querySelector(listenerConfig.eventTarget)
        if(clickElem)
          clickElem.addEventListener('click', (e) => {
            this.sendData(listenerConfig.data, csrf_token)
          });
      } else {
        console.warn('...tracking not configured')
      }
    });
  }

  sendData(eventData, csrf_token, callback=null){
    fetch("/app_event_tracker", {
      method: 'POST',
      headers: {
        'X-CSRF-Token': csrf_token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ event_data: JSON.parse(eventData) })
    })
      .then(response => response.json())
      .then((data) => {
        if(callback) callback(data);
      })
  }
}

export default AppEventTracker;